import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../../views/Login';
import PrivateRoute from '../../components/PrivateRoute';
import Dashboard from '../../views/Dashboard';
import PAGE_404 from '../../pages/presentation/auth/Page404';
import { getRouting } from '../../helpers/helpers';
import contents from '../../routes/customRoutes';
import TrainingPage from '../../views/handri/Training/TrainingPage';

const menuDB = JSON.parse(localStorage.getItem('menu'));
const presentationDraft = menuDB && getRouting(menuDB);
// get component first
const getComponent =
	presentationDraft &&
	presentationDraft.map((az) => {
		// find component on contents
		const findComponent = contents.filter((ct) => ct.index === az.element);
		const defaultComponent = findComponent.length > 0 ? findComponent[0].element : PAGE_404;
		const objC = { element: defaultComponent, path: az.path };
		return objC;
	});
const presentation =
	getComponent &&
	getComponent.map((it) => {
		const objRet = {
			element: it && it.element ? it.element : null,
			path: it && it.path ? it.path : null,
			key: `${it && it.path}pres`,
		};
		return objRet;
	});

const accessToken = JSON.parse(localStorage.getItem('accessToken'));
const ContentRoutes = () => {
	return (
		<Routes>
			<Route exact path='/list-training/training-page/:id' element={<TrainingPage />} />
			<Route exact path='/login' element={<Login />} />
			<Route
				exact
				path='/home'
				element={
					<PrivateRoute>
						<Dashboard />
					</PrivateRoute>
				}
			/>
			{accessToken &&
				presentation &&
				presentation.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route exact key={page.path} {...page} />
				))}
			<Route
				exact
				path='/'
				element={
					accessToken ? <Navigate to='/home' replace /> : <Navigate to='/login' replace />
				}
			/>
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
